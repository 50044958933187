import React from "react";
import { Helmet as Hel } from "react-helmet";

function Helmet({ title, metaDescription, slug, keywords }) {


  // const url = window.location.href;
  let url = window.location.href;  
  url = url.endsWith("/") ? url.slice(0, -1) : url;
  const domainName = new URL(url).hostname;
  return (
    <div>
      <Hel>
        {title ? <title>{title}</title> : <title>Varu By Atmosphere</title>}
        {(domainName.includes("uat") || domainName.includes("stg")  || domainName.includes("digitechsoft") || domainName.includes("digitech")  || domainName.includes("stgdigitech") ) && (
          <meta name="robots" content="noindex, nofollow" />
        )}

        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={keywords} />
        <link rel="canonical" href={url} />
      </Hel>
    </div>
  );
}

export default Helmet;
